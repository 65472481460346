import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal, Toast, ToastHeader, ToastBody } from "reactstrap";

import axios from "axios";

import logo from "../../../assets/images/logo-sm.png";

const BasicTable = () => {
  const [userApiData, setUserApiData] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ambulanceDriverId, setAmbulanceDriverId] = useState("");
  const [toast1, settoast1] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  // url
  const apiRoute = process.env.REACT_APP_LIVESERVERURL;

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // get users data
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${apiRoute}/api/laboratory/auth/allBranches`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setUserApiData(data);
    } catch (err) {
      console.log("error", err);
    }
  };

  // get api data
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toast1) {
      let toastId = setTimeout(() => {
        settoast1(false);
      }, 2000);
      return () => clearTimeout(toastId);
    }
  }, [toast1]);

  // handle delete user
  const handleDeleteDriver = async () => {
    try {
      const data = await axios.delete(
        `${apiRoute}/api/ambulance/driver/delete/${ambulanceDriverId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (data?.status === 200) {
        fetchData();
        setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        setToastMessage("Something went wrong! Try again later!");
      }
    }
    settoast1(true);
    setIsDeleteModalOpen(false);
  };

  const toggleToast1 = () => {
    settoast1(!toast1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No</span>,
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">City</span>,
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="edit"
              onClick={() => {
                setAmbulanceDriverId(cell._id);
                setIsEditModalOpen(!isEditModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-success edit-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
              >
                Edit
              </button>
            </div>
            <div
              className="remove"
              onClick={() => {
                setAmbulanceDriverId(cell._id);
                setIsDeleteModalOpen(!isDeleteModalOpen);
              }}
            >
              <button
                className="btn btn-sm btn-danger remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                Remove
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = userApiData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
      city: data.city,
      address: data.address,
      status: data.online,
    };
    return obj;
  });

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast1}>
          <ToastHeader toggle={toggleToast1}>
            <img src={logo} alt="" className="me-2" height="18" />
            Medica
          </ToastHeader>
          <ToastBody color="primary">{toastMessage}</ToastBody>
        </Toast>
      </div>
      {/* // All table data start */}
      <DataTable columns={columns} data={data2} pagination />
      {/* // All table data end */}
      {isViewModalOpen && (
        <AmbulanceDriverView
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          apiRoute={apiRoute}
          ambulanceDriverId={ambulanceDriverId}
          bearerToken={userToken}
        />
      )}
      {isEditModalOpen && (
        <AmbulanceDriverEdit
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          apiRoute={apiRoute}
          ambulanceDriverId={ambulanceDriverId}
          bearerToken={userToken}
          fetchData={fetchData}
        />
      )}
      {isDeleteModalOpen && (
        <AmbulanceDriverDelete
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          handleDeleteDriver={handleDeleteDriver}
        />
      )}
    </>
  );
};

const AmbulanceDriverView = ({
  isViewModalOpen,
  setIsViewModalOpen,
  apiRoute,
  ambulanceDriverId,
  bearerToken,
}) => {
  const [orgData, setOrgData] = useState({});

  useEffect(() => {
    const fetchSignleOrg = async () => {
      try {
        const data = await axios.get(
          `${apiRoute}/api/ambulance/driver/single/${ambulanceDriverId}`,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
        if (data?.status === 200) {
          setOrgData(data.data);
        }
      } catch (err) {
        if (err) {
        }
      }
    };

    fetchSignleOrg();
  }, [apiRoute, ambulanceDriverId, bearerToken]);

  return (
    <Modal
      isOpen={isViewModalOpen}
      toggle={() => {
        setIsViewModalOpen(!isViewModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Driver Location</h5>
        <button
          type="button"
          onClick={() => {
            setIsViewModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <iframe
            src={`https://www.google.com/maps?q=${orgData?.lat},${orgData?.long}&hl=es;&output=embed`}
            title="Driver Location"
            width="100%"
            height="400px"
            style={{ border: "none" }}
          ></iframe>
        </div>
      </div>
    </Modal>
  );
};

const AmbulanceDriverEdit = ({
  isEditModalOpen,
  setIsEditModalOpen,
  apiRoute,
  ambulanceDriverId,
  bearerToken,
  fetchData,
}) => {
  useEffect(() => {
    const fetchSignleOrg = async () => {
      try {
        const data = await axios.get(
          `${apiRoute}/api/ambulance/driver/single/${ambulanceDriverId}`,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
        if (data?.status === 200) {
          setName(data.data.name);
          setEmail(data.data.email);
          setPhoneNumber(data.data.phoneNumber);
          setAddress(data.data.address);
        }
      } catch (err) {
        if (err) {
        }
      }
    };

    fetchSignleOrg();
  }, [apiRoute, ambulanceDriverId, bearerToken]);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  const [apiLoading, setApiLoading] = useState(false);

  const handleUpdateDriver = async () => {
    try {
      setApiLoading(true);
      const data = await axios.put(
        `${apiRoute}/api/ambulance/driver/edit`,
        {
          name,
          password,
          email,
          phoneNumber,
          address,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      if (data.status === 200) {
        fetchData();
        setIsEditModalOpen(false);
      }
    } catch (err) {
      const errSplit = err.split(" ");
      const statusCode = errSplit[errSplit.length - 1];

      if (statusCode === "400") {
        alert("Something Went Wrong, Try Again");
      }
    }
    setApiLoading(false);
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      toggle={() => {
        setIsEditModalOpen(!isEditModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Driver Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsEditModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Name :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Full Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Password :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Enter New Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Phone No :
          </label>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Phone Number"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <label
            htmlFor="example-text-input"
            className="col-md-12 col-form-label"
          >
            Address :
          </label>
          <div className="col-md-12">
            <textarea
              required
              className="form-control"
              placeholder="Enter Your Address *"
              rows="3"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleUpdateDriver}
          disabled={apiLoading}
        >
          UPDATE
        </button>
      </div>
    </Modal>
  );
};

const AmbulanceDriverDelete = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  handleDeleteDriver,
}) => {
  return (
    <Modal
      isOpen={isDeleteModalOpen}
      toggle={() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Are You Sure to Delete This Ambulance Driver?
        </h5>
        <button
          type="button"
          onClick={() => {
            setIsDeleteModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => {
            setIsDeleteModalOpen(false);
          }}
        >
          No
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDeleteDriver}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export { BasicTable };
