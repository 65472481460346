import axios from "axios";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Modal, Col, Container, Row } from "reactstrap";

const apiRoute = process.env.REACT_APP_LIVESERVERURL;

const BasicTable = ({ orderData, fetchOrderData }) => {
  const [testId, setTestId] = useState("");
  const [viewOrderId, setViewOrderId] = useState("");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Name</span>,
      selector: (row) => row.patientName,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Email</span>,
      selector: (row) => row.patientEmail,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Phone No</span>,
      selector: (row) => row.patientPhone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Patient Gender</span>,
      selector: (row) => row.patientGender,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Test Length</span>,
      selector: (row) => row.testLength,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">View</span>,
      sortable: true,
      cell: (cell) => {
        return (
          <div className="d-flex gap-2">
            <div
              className="remove"
              onClick={() => {
                setTestId(cell.testId);
                setViewOrderId(cell.orderId);
                setIsViewModalOpen(!isViewModalOpen);
              }}
            >
              <button
                className="btn btn-sm remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#deleteRecordModal"
              >
                <i className="fas fa-eye"></i>
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const data2 = orderData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      orderId: data.orderId,
      patientName: data.patientInfo.fullName,
      patientEmail: data.patientInfo.email,
      patientPhone: data.patientInfo.phoneNo,
      patientGender: data.patientInfo.gender,
      testLength: data.tests.length,
    };
    return obj;
  });

  return (
    <>
      <DataTable columns={columns} data={data2} pagination />

      {/* view doctor */}
      {isViewModalOpen && (
        <ViewData
          isViewModalOpen={isViewModalOpen}
          setIsViewModalOpen={setIsViewModalOpen}
          viewOrderId={viewOrderId}
          orderData={orderData}
          fetchOrderData={fetchOrderData}
        />
      )}
    </>
  );
};

const AddOrderTime = ({
  isEditModalOpen,
  setIsEditModalOpen,
  apiRoute,
  id,
  orderId,
  fetchData,
}) => {
  const [timeAndDate, setTimeAndDate] = useState("");

  let user = sessionStorage.getItem("authUser");
  user = JSON.parse(user);
  const userToken = user.token;

  // handle delete user
  const handleUpdateUser = async () => {
    try {
      const data = await axios.put(
        `${apiRoute}/api/laboratory/auth/updateLabTestOrderData`,
        {
          mainId: id,
          testListId: orderId,
          timeAndDate,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (data?.status === 200) {
        fetchData();
        // setToastMessage(data.message);
      }
    } catch (err) {
      if (err) {
        // setToastMessage("Something went wrong! Try again later!");
      }
    }
    // settoast1(true);
    setIsEditModalOpen(false);
  };

  return (
    <Modal
      isOpen={isEditModalOpen}
      toggle={() => {
        setIsEditModalOpen(!isEditModalOpen);
      }}
      centered
      // className="modal-lg"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Organization Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsEditModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="recipient-name" className="col-form-label">
            Add Time :{" "}
            <Input
              name="email"
              className="form-control"
              placeholder="Enter email"
              type="datetime-local"
              onChange={(e) => setTimeAndDate(e.target.value)}
            />
            <br />
            Id : {id}
            <br />
            orderId : {orderId}
          </label>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleUpdateUser}
          disabled={!timeAndDate}
        >
          Add
        </button>
      </div>
    </Modal>
  );
};

const ViewData = ({
  isViewModalOpen,
  setIsViewModalOpen,
  viewOrderId,
  orderData,
  fetchOrderData,
}) => {
  const [id, setId] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const viewOrderData = orderData.filter(
    (data) => data.orderId === viewOrderId
  );

  return (
    <Modal
      isOpen={isViewModalOpen}
      toggle={() => {
        setIsViewModalOpen(!isViewModalOpen);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Organization Data</h5>
        <button
          type="button"
          onClick={() => {
            setIsViewModalOpen(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          {viewOrderData[0].tests.length > 0 &&
            viewOrderData[0].tests.map((test, index) => {
              let formattedDate = "";

              if (!!test.testReportTime) {
                const dateObj = new Date(test.testReportTime);
                const day = dateObj.getDate();
                const month = dateObj.toLocaleString("default", {
                  month: "short",
                });
                const year = dateObj.getFullYear();
                let hours = dateObj.getHours();
                const minutes = dateObj
                  .getMinutes()
                  .toString()
                  .padStart(2, "0");

                const ampm = hours >= 12 ? "PM" : "AM";
                hours = hours % 12 || 12;
                formattedDate = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
              }

              return (
                <div
                  key={test._id}
                  className="mb-3 shadow-lg p-2 d-flex flex-column gap-1"
                >
                  <div>Test Name: {test.testName}</div>
                  <div>Test Price: ${test.testPrice}</div>
                  <div>Test Report Time: {formattedDate}</div>
                  <button
                    className="btn btn-primary waves-effect waves-light w-75 mx-auto"
                    onClick={() => {
                      setId(test._id);
                      setIsEditModalOpen(!isEditModalOpen);
                    }}
                  >
                    Add Report Time
                  </button>
                </div>
              );
            })}
        </div>
        {/* add time for order */}
        {isEditModalOpen && (
          <AddOrderTime
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            apiRoute={apiRoute}
            id={viewOrderId}
            orderId={id}
            fetchData={fetchOrderData}
          />
        )}
      </div>
    </Modal>
  );
};

export { BasicTable };
