export const SidebarDataOrg = [
  {
    label: "Pages",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    isHasArrow: true,
    url: "/dashboard",
  },
  {
    label: "Create Lab Branch",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/create-lab-branch",
  },
  {
    label: "Labs List",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/labs-list",
  },
];

export const SidebarDataBranch = [
  {
    label: "Pages",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "ri-bar-chart-line",
    isHasArrow: true,
    url: "/dashboard",
  },
  {
    label: "Test Management",
    icon: "ri-table-2",
    isHasArrow: true,
    url: "/test-management",
  },
  {
    label: "Order Management",
    icon: "mdi mdi-account-circle-outline",
    isHasArrow: true,
    url: "/order-management",
  },
  {
    label: "Customer",
    icon: "mdi mdi-briefcase-variant-outline",
    isHasArrow: true,
    url: "/customer",
  },
  {
    label: "User Management",
    icon: "ri-bar-chart-line",
    isHasArrow: true,
    url: "/user-management",
  },
];
