import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { BasicTable } from "./datatableCom";

const ProductManagement = (props) => {
  document.title = "Branches List";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Laboratory Organization"
            breadcrumbItem="Branches"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">All Laboratory Branches</h5>
                </CardHeader>
                <CardBody>
                  <BasicTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ProductManagement;
