import React from "react";
import DataTable from "react-data-table-component";

const BasicTable = ({ testApiData }) => {
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row.srNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Test Name</span>,
      selector: (row) => row.testTitle,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      selector: (row) => `$${row.price}`,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Duration</span>,
      selector: (row) => row.duration,
      sortable: true,
    },
  ];

  const data2 = testApiData.map((data, index) => {
    const obj = {
      _id: data._id,
      srNo: index + 1,
      price: data.price,
      duration: data.duration,
      testTitle: data.testTitle,
    };
    return obj;
  });

  return (
    <>
      <DataTable columns={columns} data={data2} pagination />
    </>
  );
};

export { BasicTable };
