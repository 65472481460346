const backURL = process.env.REACT_APP_LIVESERVERURL;

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const USER_LOGIN = `${backURL}/api/laboratory/auth/loginBranch`;
export const USER_LOGIN_ORG = `${backURL}/api/laboratory/auth/login`;
export const USER_LOGIN_BRANCH = `${backURL}/api/laboratory/auth/loginBranch`;
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";
export const PUT_EDIT_PROFILE = `${backURL}/api/laboratory/auth/changeBranchPassword`;

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";
