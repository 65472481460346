import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/no-avatar.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import { createSelector } from "reselect";

const UserProfile = () => {
  document.title = "Profile | Laboratory Panel - Admin Dashboard";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const userprofilepage = createSelector(
    (state) => state.profile,
    (state) => ({
      error: state.error,
      success: state.success,
    })
  );
  // Inside your component
  const { error, success } = useSelector(userprofilepage);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setPassword("");
      setemail(obj.email);
      setPhoneNo(obj.phoneNo);

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      useremail: email || "",
      userpassword: password || "",
    },
    validationSchema: Yup.object({
      userpassword: Yup.string()
        .required("Please Enter Your Password")
        .min(6, "Password is too short - should be 6 chars minimum."),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Laboratory Panel" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? (
                <Alert color="danger">
                  <div>{error}</div>
                </Alert>
              ) : null}
              {success ? (
                <Alert color="success">
                  <div>{success}</div>
                </Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center mx-2">
                      <div className="text-muted">
                        <p className="mb-1">{email}</p>
                        <p className="mb-1">{phoneNo}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Password</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Password</Label>
                  <Input
                    name="userpassword"
                    className="form-control"
                    placeholder="Enter User Password"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.userpassword || ""}
                    invalid={
                      validation.touched.userpassword &&
                      validation.errors.userpassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.userpassword &&
                  validation.errors.userpassword ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.userpassword}</div>
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
